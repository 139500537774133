import * as React from "react";
import Youtube from "react-youtube";
import { ApolloClient, HttpLink, ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { cache } from "../../cache";

function currentCsrfToken(): string {
  return metaTokenElement().getAttribute("content") || "";
}

function metaTokenElement() {
  const element = document.head.querySelector('meta[name="csrf-token"]');
  if (element) {
    return element;
  } else {
    return {
      getAttribute: (string: string) => {
        return "";
      }
    };
  }
}

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    new HttpLink({
      uri: "/graphql",
      credentials: "same-origin",
      headers: {
        "X-CSRF-Token": currentCsrfToken()
      }
    })
  ]),
  cache,
  connectToDevTools: process.env.RAILS_ENV === "development"
});

export default class Course extends React.Component<
  {
    course: {
      id: string;
      videoId: string;
      disableScrubber: boolean;
      showSurvey: boolean;
      surveyUrlWithMetadata: string;
      name: string;
      exam: { id: string };
      nextCourse: { name: string } | null;
      nextCourseUrl: string;
      isExport: boolean;
      needsToWatch: boolean;
      hasWatchedVideo: boolean;
    };
  },
  { sessionId: number; identifier: string }
> {
  constructor(props) {
    super(props);

    this.state = {
      sessionId: new Date().getTime(),
      identifier: props.course.identifier
    };
  }

  createActivity = (event, value) => {
    $.ajax({
      type: "POST",
      url: "/course_activities",
      data: {
        logged_event: {
          session_id: this.state.sessionId,
          event: event,
          value: value,
          course_id: this.props.course.id
        }
      },
      dataType: "json"
    });
  };

  sendLms = (event) => {
    $.ajax({
      type: "POST",
      url: "/lms/activities",
      data: {
        identifier: this.state.identifier,
        event: event
      }
    });
  };

  render() {
    const course = this.props.course;

    let opts = {
      playerVars: {
        modestbranding: 1,
        rel: 0,
        controls: 1
      }
    };

    if (course.disableScrubber) {
      opts.playerVars.controls = 0;
    }

    const SD = (window as any).SD;

    return (
      <React.Fragment>
        <div className="embed-responsive embed-responsive-16by9 mx-auto col-md-12 col-lg-10 col-xl-9 js-courseplayer-video" data-watched={course.hasWatchedVideo} data-needs_to_watch={course.needsToWatch}>
          <Youtube
            videoId={course.videoId}
            opts={opts}
            onPlay={(event) => {
              if (course.isExport) {
                this.sendLms("video-started");
              } else {
                this.createActivity("play", event.target.getCurrentTime());
              }
            }}
            onPause={(event) => {
              if (!course.isExport) {
                this.createActivity("stop", event.target.getCurrentTime());
              }
            }}
            onEnd={(event) => {
              $(".js-courseplayer-video").data("watched", true);
              if (course.isExport) {
                
                this.sendLms("video-completed");
                if (course.exam) {
                  SD.SetBookmark("exam");
                  $(".js-exam-launcher").show();
                } else {
                  SD.SetPassed();
                  $(".complete-content").append(
                    '<p><a href="#" class="btn btn-primary" onclick="SD.ConcedeControl()">Exit Course</a>'
                  );
                }

                SD.CommitData();

                $(".js-courseplayer-video").toggleClass(
                  "courseplayer-video--minimize",
                  false
                );
                $(".js-courseplayer-complete").show();
              } else {
                this.createActivity("completed", event.target.getCurrentTime());
                this.setState({ sessionId: new Date().getTime() });
                $(".js-courseplayer-complete").show();
              }
            }}
            onError={(event) => {
              console.log("Something happened");
            }}
          />
        </div>
        <div
          className="courseplayer-complete js-courseplayer-complete"
          style={{ display: "none" }}
        >
          <div className="complete-content">
            <p>You have completed watching {course.name}.</p>
            <p>
              {course.exam && (
                <button
                  className={`js-exam-launcher btn btn-primary test-exam-id-${course.exam.id}`}
                >
                  take the exam
                </button>
              )}
              {course.showSurvey && (
                <button
                  className="btn btn-secondary"
                  data-toggle="modal"
                  data-target="#js-survey-iframe"
                >
                  take the survey
                </button>
              )}
            </p>

            {course.nextCourse && (
              <p>
                The next course is{" "}
                <a href={course.nextCourseUrl}>{course.nextCourse.name}</a>.
              </p>
            )}
          </div>
        </div>

        {course.showSurvey && (
          <div className="modal fade" id="js-survey-iframe">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal">
                    <span aria-hidden="true">
                      <i className="fal fa-times-circle" />
                    </span>
                    <span className="sr-only">Close</span>
                  </button>
                  <h3>Results</h3>
                </div>
                <div className="modal-body">
                  <iframe
                    src={course.surveyUrlWithMetadata}
                    className="qa-survey-iframe"
                    frameBorder="0"
                    width="100%"
                    height="500"
                    style={{ overflow: "hidden" }}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
